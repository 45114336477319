<div class="snackbar" matSnackBarLabel>
  <p>
    An update of the application is available, and you may reload the app now to use the latest version.
  </p>
  <p>
    The new version is {{ data.available.appData.commit || data.available.hash }},
    <span *ngIf="data.available.appData.build_time; let build_time">
      built on {{ build_time }},
    </span> <br>
    and the changes can be seen in the <a href="/app/changelog?since={{ data.current.appData.build_epoch }}">Runbox 7 changelog</a>.<br />
    </p>
  </div>
<div style="display: flex" matSnackBarActions>
    <span style="flex-grow: 1"></span>
    <button mat-button matSnackBarAction (click)="reload()">Reload now</button>
    <button mat-button matSnackBarAction (click)="close()">Later</button>
</div>
