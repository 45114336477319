<div *ngIf="messageId" [ngClass]="{'adjustableHeight': adjustableHeight}"
  appHorizResizable [resizableDisabled]="!adjustableHeight" (resized)="heightChanged($event)">
  <iframe #printFrame
    sandbox="allow-same-origin allow-modals"
    style="width: 0px; height: 0px; border: none; position: absolute"
  ></iframe>
  <mat-toolbar *ngIf="mailObj" style="display: flex; overflow-x: auto;">
    
    <!-- Message preview toolbar -->
    <!-- NOTE: These items need to be replicated in the overflow toolbar further down. The number of items shown is controlled by morebuttonindex and TOOLBAR_BUTTON_WIDTH in the .ts file. -->
    
    <div class="messageActionButtonsLeft" #toolbarButtonContainer>
      <button *ngIf="morebuttonindex>0" mat-icon-button matTooltip="Move message to folder..." (click)="messageActionsHandler.moveToFolder()">
        <mat-icon svgIcon="folder"></mat-icon>
      </button>
      <button *ngIf="morebuttonindex>1"
        mat-icon-button
        [matTooltip]="folder !== this.messagelistservice.trashFolderName ? 'Move message to Trash' : 'Delete permanently'"
        (click)="messageActionsHandler.deleteMessage()"
      >
        <mat-icon
            *ngIf="folder !== this.messagelistservice.trashFolderName; else deletePermanently"
            svgIcon="delete"
        >
        </mat-icon>
        <ng-template #deletePermanently>
          <mat-icon svgIcon="delete-forever"></mat-icon>
        </ng-template>
      </button>
      <button *ngIf="morebuttonindex>2" mat-icon-button matTooltip="Reply" (click)="messageActionsHandler.reply(showHTML)">
	      <mat-icon svgIcon="reply"></mat-icon>
      </button>
      <button *ngIf="morebuttonindex>3" mat-icon-button matTooltip="Reply to all" (click)="messageActionsHandler.replyToAll(showHTML)">
        <mat-icon svgIcon="reply-all"></mat-icon>
      </button>
      <button *ngIf="morebuttonindex>4" mat-icon-button matTooltip="Forward" (click)="messageActionsHandler.forward(showHTML)">
        <mat-icon svgIcon="forward"></mat-icon>
      </button>
      <ng-container *ngIf="morebuttonindex>5">
        <button *ngIf="mailObj.seen_flag===1" mat-icon-button matTooltip="Mark unread" (click)="messageActionsHandler.markSeen(0)">
        <mat-icon svgIcon="email-mark-as-unread"></mat-icon>
        </button>
        <button *ngIf="mailObj.seen_flag===0" mat-icon-button matTooltip="Mark read" (click)="messageActionsHandler.markSeen(1)">
        <mat-icon svgIcon="email-open"></mat-icon>
        </button>
       </ng-container>
      <ng-container *ngIf="morebuttonindex>6">
        <button mat-icon-button *ngIf="mailObj.flagged_flag===0" matTooltip="Flag" (click)="messageActionsHandler.flag()">
          <mat-icon svgIcon="flag"></mat-icon>
        </button>
        <button *ngIf="mailObj.flagged_flag===1" mat-icon-button matTooltip="Remove flag" (click)="messageActionsHandler.unflag()">
  	  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="mat-icon">
	    <path d="M14 6l-1-2H5v17h2v-7h5l1 2h7V6h-6zm4 8h-4l-1-2H7V6h5l1 2h5v6z"/>
	    <path fill="none" d="M0 0h24v24H0z"/>
	  </svg>
        </button>
      </ng-container>
      <ng-container *ngIf="morebuttonindex>7">
        <button mat-icon-button *ngIf="folder!==this.messagelistservice.spamFolderName" matTooltip="Report spam" (click)="messageActionsHandler.trainSpam({is_spam:1})">
          <mat-icon svgIcon="alert-octagon"></mat-icon>
        </button>
        <button mat-icon-button *ngIf="folder===this.messagelistservice.spamFolderName" matTooltip="Not spam" (click)="messageActionsHandler.trainSpam({is_spam:0})">
          <mat-icon svgIcon="alert-octagon-outline"></mat-icon>
        </button>
      </ng-container>
      <button mat-icon-button *ngIf="morebuttonindex>8" matTooltip="Block/Allow Sender" [matMenuTriggerFor]="senderRulesMenu">
        <mat-icon svgIcon="filter-cog"></mat-icon>
      </button>
      <a *ngIf="morebuttonindex>9" mat-icon-button
        matTooltip="View source" [href]="'/rest/v1/email/'+messageId+'/raw'" target="_blank">
        <mat-icon svgIcon="code-tags"></mat-icon>
      </a>
      <button mat-icon-button *ngIf="mailContentHTML && morebuttonindex>10" matTooltip="HTML settings" [matMenuTriggerFor]="htmlSettingsMenu">
        <mat-icon svgIcon="cog"></mat-icon>
      </button>
      <a *ngIf="mailContentHTML && morebuttonindex>11" mat-icon-button
        matTooltip="Original HTML" [href]="'/rest/v1/email/'+messageId+'/html'" target="_blank">
        <mat-icon svgIcon="alert"></mat-icon>
      </a>
      <button mat-icon-button *ngIf="morebuttonindex < 12" [matMenuTriggerFor]="moreMailActionsMenu" matTooltip="More message actions">
        <mat-icon svgIcon="dots-vertical"></mat-icon>
      </button>

      <mat-menu #senderRulesMenu>
        <button mat-menu-item (click)="messageActionsHandler.allowListSender(mailObj.from[0].address)">
          AllowList Sender
        </button>
        <button mat-menu-item (click)="messageActionsHandler.blockSender(mailObj.from[0].address)">
          Block Sender Email
        </button>
        <button mat-menu-item (click)="messageActionsHandler.blockSender(mailObj.from[0].domain)">
          Block Sender Domain
        </button>
        <a mat-menu-item
           [href]="'/mail/rules'" target="rmm6">
          Manage Blocking Rules
        </a>
      </mat-menu>

      <mat-menu #htmlSettingsMenu="matMenu">
          <mat-radio-group
            style="display: inline-flex; flex-direction: column"
            [(ngModel)]="showHTMLDecision" (change)="saveShowHTMLDecision()">
            <mat-radio-button class="menuradiobutton" value="dontask">
                Toggle HTML
              </mat-radio-button>
            <mat-radio-button class="menuradiobutton" value="alwaysshowhtml">
                Always show HTML
              </mat-radio-button>
          </mat-radio-group>
          <mat-radio-group
            style="display: inline-flex; flex-direction: column"
            [(ngModel)]="showImagesDecision" (change)="saveShowImagesDecision()">
              <mat-radio-button class="menuradiobutton" value="dontask">
                Toggle Images
              </mat-radio-button>
              <mat-radio-button class="menuradiobutton" value="alwaysshowimages">
                Always show Images
              </mat-radio-button>
          </mat-radio-group>
      </mat-menu>
      
      <!-- Message preview toolbar overflow items -->
      <mat-menu #moreMailActionsMenu="matMenu">
        <button *ngIf="morebuttonindex<1" mat-menu-item (click)="messageActionsHandler.moveToFolder()">
          <mat-icon svgIcon="folder"></mat-icon>
          <span>Move to folder</span>
        </button>
        <button *ngIf="morebuttonindex<2" mat-menu-item (click)="messageActionsHandler.deleteMessage()">
          <span *ngIf="folder !== this.messagelistservice.trashFolderName; else deletePermanently2">
            <mat-icon svgIcon="delete"></mat-icon>
            Move to Trash
          </span>
          <ng-template #deletePermanently2>
            <mat-icon svgIcon="delete-forever"></mat-icon>
            Delete permanently
          </ng-template>
        </button>
        <button *ngIf="morebuttonindex<3" mat-menu-item (click)="messageActionsHandler.reply(showHTML)">
          <mat-icon svgIcon="reply"></mat-icon>
          <span>Reply</span>
        </button>
        <button *ngIf="morebuttonindex<4" mat-menu-item (click)="messageActionsHandler.replyToAll(showHTML)">
          <mat-icon svgIcon="reply-all"></mat-icon>
          <span>Reply to all</span>
        </button>
        <button *ngIf="morebuttonindex<5" mat-menu-item (click)="messageActionsHandler.forward(showHTML)">
          <mat-icon svgIcon="forward"></mat-icon>
          <span>Forward</span>
        </button>
        <button *ngIf="morebuttonindex<6" mat-menu-item (click)="messageActionsHandler.markSeen(0)">
          <mat-icon svgIcon="email-mark-as-unread"></mat-icon>
          <span>Mark unread</span>
        </button>
        <ng-container *ngIf="morebuttonindex<7">
          <button mat-menu-item *ngIf="mailObj.flagged_flag===0" (click)="messageActionsHandler.flag()">
            <mat-icon svgIcon="flag"></mat-icon>
            <span>Flag</span>
          </button>
          <button *ngIf="mailObj.flagged_flag===1" mat-menu-item (click)="messageActionsHandler.unflag()" tooltip="Remove flag">
            <mat-icon svgIcon="flag-outline"></mat-icon>
            <span>Remove flag</span>
          </button>
        </ng-container>
        <ng-container *ngIf="morebuttonindex<8">
          <button mat-menu-item *ngIf="folder!=='Spam'" (click)="messageActionsHandler.trainSpam({is_spam:1})">
            <mat-icon svgIcon="alert-octagon"></mat-icon>
            <span>Report spam</span>
          </button>
          <button mat-menu-item *ngIf="folder==='Spam'" (click)="messageActionsHandler.trainSpam({is_spam:0})">
            <mat-icon svgIcon="alert-octagon-outline"></mat-icon>
            <span>Not spam</span>
          </button>
        </ng-container>
        <button mat-menu-item *ngIf="morebuttonindex<9" [matMenuTriggerFor]="blockSenderMenu">
          <mat-icon svgIcon="block"></mat-icon>
          <span>Block Sender/Domain</span>
        </button>
        <a *ngIf="morebuttonindex<9" mat-menu-item
          [href]="'/rest/v1/email/'+messageId+'/raw'" target="_blank">
          <mat-icon svgIcon="code-tags"></mat-icon>
          <span>View source</span>
        </a>
        <button mat-menu-item *ngIf="mailContentHTML && morebuttonindex<10" [matMenuTriggerFor]="htmlSettingsMenu">
          <mat-icon svgIcon="cog"></mat-icon>
          <span>HTML settings</span>
        </button>
	<a *ngIf="mailContentHTML && morebuttonindex<11" mat-menu-item
          [href]="'/rest/v1/email/'+messageId+'/html'" target="_blank">
          <mat-icon svgIcon="alert"></mat-icon>
	  <span>Original HTML</span>
	</a>
      </mat-menu>
    </div>

    <div class="messageActionButtonsRight">
      <button mat-icon-button matTooltip="Print message (Please consider the environment before printing this email)" (click)="print()" id="buttonPrint">
	<mat-icon svgIcon="printer"></mat-icon>
      </button>
      <button mat-icon-button matTooltip="Horizontal preview" *ngIf="!adjustableHeight"
	      (click)="changeOrientation('horizontal')">
	<mat-icon svgIcon="view-split-horizontal"></mat-icon>
      </button>
      <button mat-icon-button matTooltip="Vertical preview" *ngIf="adjustableHeight && showVerticalSplitButton"
	      (click)="changeOrientation('vertical')">
	<mat-icon svgIcon="view-split-vertical"></mat-icon>
      </button>
      <button mat-icon-button *ngIf="adjustableHeight && resizer?.isFullHeight===false" (click)="resizer.resizePercentage(100)" matTooltip="Full height">
	<mat-icon class="messageHeightIcon" svgIcon="arrow-up-drop-circle"></mat-icon>
      </button>
      <button mat-icon-button *ngIf="adjustableHeight && resizer?.isFullHeight===true" (click)="resizer.resizePercentage(50)" matTooltip="Half height">
	<mat-icon class="messageHeightIcon" svgIcon="arrow-down-drop-circle"></mat-icon>
      </button>
      
      <button mat-icon-button (click)="close()" matTooltip="Close">
	<mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div *ngIf="mailObj" #messageContents id="messageContents">
    <mat-expansion-panel [expanded]="mobileQuery.matches ? false : true" id="messageHeader">
      <mat-expansion-panel-header class="messageHeaders" title="Toggle message headers">
	      <mat-panel-title>
          <app-avatar-bar [email]="mailObj"> </app-avatar-bar>
          <div id="messageHeaderSubject">{{mailObj.subject}}</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div id="headerButtons" style="position: absolute; right: 3px;">
        <button mat-icon-button matTooltip="Show all headers" *ngIf="!showAllHeaders" (click)="showAllHeaders=true"><mat-icon svgIcon="unfold-more-horizontal"></mat-icon></button>
        <button mat-icon-button matTooltip="Show basic headers" *ngIf="showAllHeaders" (click)="showAllHeaders=false"><mat-icon svgIcon="unfold-less-horizontal"></mat-icon></button>
      </div>
      <div class="messageHeaders">
      <ng-container *ngIf="!showAllHeaders">
          <div class="messageHeaderRow">
            <div class="messageHeaderFrom">
              From: <rmm7-contact-card [contact]="mailObj.from[0]"></rmm7-contact-card>
            </div>
          </div>
          <div class="messageHeaderRow">
            <div class="messageHeaderDate">Time:</div>
            <div class="messageHeaderDate">{{mailObj.date | date:'yyyy-MM-dd HH:mm ZZZZ'}}</div>
          </div>
          <div *ngIf="mailObj.to" class="messageHeaderRow">
            <div class="messageHeaderTo">
              To: <rmm7-contact-card *ngFor="let to of mailObj.to" [contact]="to"></rmm7-contact-card>
            </div>
          </div>
          <div *ngIf="mailObj.cc" class="messageHeaderRow">
            <div class="messageHeaderCC">
              CC: <rmm7-contact-card *ngFor="let to of mailObj.cc" [contact]="to"></rmm7-contact-card>
            </div>
          </div>
          <div *ngIf="mailObj.bcc"class="messageHeaderRow">
            <div class="messageHeaderBCC">
              BCC: <rmm7-contact-card *ngFor="let to of mailObj.bcc" [contact]="to"></rmm7-contact-card>
            </div>
          </div>
        </ng-container>
        <table *ngIf="showAllHeaders">
          <tr *ngFor="let header of mailObj.headers | keyvalue" style="font-size: 12px;">
            <td style="vertical-align: top; white-space: nowrap; font-weight: bold;">{{header.key}}</td>
            <td style="vertical-align: top;">
              <span *ngIf="header.value.html" [innerHTML]="header.value.html"></span>
              <span *ngIf="header.value.unsubscribe">
                Unsubscribe:
                <a [href]="header.value.unsubscribe.url">
                  {{header.value.unsubscribe.url}}
                </a>
              </span>
              <span *ngIf="!header.value.html && !header.value.unsubscribe">{{header.value | json}}</span>
            </td>
          </tr>
        </table>
      </div>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="showAttachments" id="attachmentsListHeader">
      <mat-expansion-panel-header class="messageHeaders" title="Toggle attachment list">
        <p mat-line>
          <mat-icon mat-list-icon svgIcon="attachment"></mat-icon> <span> Attachment List</span>
        </p>
      </mat-expansion-panel-header>
    
      <ng-container>
        <mat-list>
          <mat-list-item class="link-pointer" *ngFor="let att of mailObj.attachments; let i=index" (click)="openAttachment(att)">
            <ng-container *ngIf="!att.internal || !(mailContentHTML && showHTML && SUPPORTS_IFRAME_SANDBOX)">
              <mat-icon mat-list-icon svgIcon="{{attachmentIconFromContentType(att.contentType)}}"></mat-icon> <span>{{att.filename}}</span><span style="flex-grow: 1"></span><span>{{att.sizeDisplay}}</span>
            </ng-container>
          </mat-list-item>
        </mat-list>
      </ng-container>
    </mat-expansion-panel>
    <mat-divider></mat-divider>

    <div class="htmlButtons" *ngIf="mailContentHTML">
	Show:
        <mat-radio-group
          aria-label="Toggle Plain/HTML view"
          [value]="showHTML ? 'HTML' : 'Plain'"
          >
          <mat-radio-button
            (click)="toggleHtml($event)"
            value="Plain"
            matTooltip="Toggle Plain Text version of message"
            >
            <mat-icon svgIcon="format-letter-case"></mat-icon>
          </mat-radio-button>
          <mat-radio-button
            (click)="toggleHtml($event)"
            value="HTML"
            matTooltip="Toggle HTML version of message"
            >
            <mat-icon svgIcon="format-float-left"></mat-icon>
          </mat-radio-button>
        </mat-radio-group>
	<mat-checkbox *ngIf="showHTML"
          (click)="showExternalImages($event)"
          [checked]="showImages"
          matTooltip="Show external images"
	  >
          <mat-icon svgIcon="image"></mat-icon>
	</mat-checkbox>
        <mat-checkbox *ngIf="!showHTML"
          color="gray"
          matTooltip="Show external images (only in HTML view)"
          >
          <mat-icon svgIcon="image" class="themePaletteDarkGray"></mat-icon>
        </mat-checkbox>
	<span class="space"></span>
        <span> For: </span>
        <button
          [ngClass]="savedForThisSender ? 'mat-flat-button' : 'mat-raised-button'"
          (click)="saveDecisionForThisSender($event)"
          matTooltip="Save setting for this sender"
          >
	  <mat-icon svgIcon="account"></mat-icon>
        </button>
        <button
          [ngClass]="savedAlways ? 'mat-flat-button' : 'mat-raised-button'"
          (click)="saveDecisionGlobal($event)"
          matTooltip="Save setting for all senders"
          >
	  <mat-icon svgIcon="earth"></mat-icon>
        </button>
	<span class="space"></span>
        <mat-icon color="warn" svgIcon="alert" matTooltip="Only show HTML messages from trusted senders"></mat-icon>
    </div>

    <!-- We have an HTML message -->
    <div *ngIf="showHTML && !SUPPORTS_IFRAME_SANDBOX">
      <div class="notificationContainer">
	<span class="inMessageNotification">This HTML message has been sanitized for your security.</span>
      </div>
      <div [innerHTML]="mailContentHTML"></div>
    </div>

    <div *ngIf="showHTML && SUPPORTS_IFRAME_SANDBOX" class="notificationContainer">
      <span class="inMessageNotification">This HTML message has been sanitized for your security.</span>
    </div>

    <!-- We have an HTML message, but no text version and the user has not chosen to show HTML -->
    <div *ngIf="mailContentHTML && !showHTML && (mailObj.text === 'undefined' || mailObj.text.changingThisBreaksApplicationSecurity == '')" class="notificationContainer">
      <span class="inMessageNotification">No text version of this message is available.<br />Please click the HTML button above to view the HTML version.</span>
    </div>

    <!-- We have an empty message -->
    <div *ngIf="!mailContentHTML && !showHTML && mailObj.text === 'undefined'" class="notificationContainer" id="messageContentsBody">
      <span class="inMessageNotification">This message has no content.</span>
    </div>

    <!-- We have a plain text message or the user has chosen to show text -->
    <div *ngIf="!showHTML && mailObj.text !== 'undefined'" id="messageContentsBody" style="white-space: pre-wrap" [innerHTML]='mailObj.text'>
    </div>

    <div *ngIf="mailContentHTML && showHTML && SUPPORTS_IFRAME_SANDBOX" class="iframe-container">
      <iframe
	        #htmliframe
          [srcdoc]="mailContentHTML"
          (load)="adjustIframeHTMLHeight()"
          sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          id="iframe"
	    >
      </iframe>
    </div>

    <mat-card #attachmentsSection *ngIf="showAttachments">
      <mat-card-content>
        <mat-grid-list [cols]="attachmentAreaCols" gutterSize="5px">
          <mat-grid-tile
            *ngFor="let att of mailObj.attachments; let i=index"
            (click)="openAttachment(att)"
            style="max-width: 150px">
            <mat-grid-tile-header>
              <span class="string-ellipsis">{{att.sizeDisplay}}</span>
            </mat-grid-tile-header>
              <ng-container *ngIf="!att.internal || !(mailContentHTML && showHTML && SUPPORTS_IFRAME_SANDBOX)">
                <ng-container *ngIf="att.thumbnailURL; else attachment_icon">
                  <img  matListAvatar
                    [src]="att.thumbnailURL" />
                </ng-container>
                <ng-template #attachment_icon>
                  <mat-icon class="icon-bigger" matListAvatar svgIcon="{{attachmentIconFromContentType(att.contentType)}}"></mat-icon>
                </ng-template>
                <mat-grid-tile-footer style="display: flex; text-align: center; justify-content: space-between;">
                    <span class="string-ellipsis">{{att.filename}}</span>
                    <button mat-icon-button *ngIf="att.filename==='encrypted.asc'"
                      matTooltip="Decrypt message"
                      (click)="decryptAttachment(i);$event.stopPropagation()"
                    ><mat-icon svgIcon="lock-open"></mat-icon></button>
                    <button mat-icon-button
                      (click)="downloadAttachmentFromServer(i);$event.stopPropagation()"
                    ><mat-icon svgIcon="cloud-download"></mat-icon></button>
                </mat-grid-tile-footer>
              </ng-container>
          </mat-grid-tile>
        </mat-grid-list>

      </mat-card-content>
    </mat-card>
  </div>
  <div *ngIf="!mailObj && !err">
    <app-runbox-loading text="Loading your message"></app-runbox-loading>
  </div>
  <app-runbox-contact-support *ngIf="err">
    There was an error loading your message: {{ err }}.
  </app-runbox-contact-support>
</div>
