<canvas #thecanvas style="position: absolute; user-select: none;" 
    tabindex="0"
    ></canvas>
    <div #columnOverlay draggable="true" [matTooltip]="floatingTooltip.tooltipText" 
        style="position: absolute;"
            (DOMMouseScroll)="floatingTooltip=null"
            (mousewheel)="floatingTooltip=null"
            (mousemove)="canv.onmousemove($event)"               
            (click)="columnOverlayClicked($event)"
            [style.top.px]="floatingTooltip.top" 
            [style.left.px]="floatingTooltip.left"
            [style.width.px]="floatingTooltip.width"
            [style.height.px]="floatingTooltip.height"
            *ngIf="floatingTooltip" 
            (dragstart)="dragColumnOverlay($event)"
            >              
</div>
